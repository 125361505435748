import capi from '../capi/instance';
import { CommonRes, virtualmanType } from 'types/index';

export type BotItem = {
  botKey: string;
  botName: string;
};

export type ModelBotItem = {
  botBizId: string;
  name: string;
};

interface ProjectListReq {
  offset: number;
  limit: number;
  filter?: {
    virtualmanInstanceName?: string;
    virtualmanTypeCode?: string;
    driverTypes?: string;
    virtualmanTypeV2?: string;
    botBizId?: string;
  };
  cgiHeader?: any;
}

interface ProjectListRes extends CommonRes {
  offset: string;
  limit: string;
  totalCount: number;
  records: {
    virtualmanInstanceName: string;
    createTime: string;
    virtualmanKey: string;
    previewUrl: string;
    repaySource: string;
    virtualmanTypeCode: string;
    valid?: number; // 是否有效 0:无效 1:有效
    botKey?: string;
    archVersion?: number;
    schemeSelection: number; // 1:API接入 2:端渲染SDK接入
    isSmallSample?: number;
    creator: string;
  }[];
}

export type DeleteProjectReq = {
  virtualmanKey: string;
};

export interface SaveProjectReq {
  virtualmanKey?: string;
  botKey?: string;
  instanceName: string;
  instanceDescrible: string;
  virtualmanTypeCode: string;
  repaySource: string;
  maxConcurrent: number;
  botKeyBindType: number;
  schemeSelection: number;
  imageTemplateId?: number;
  change?: boolean; // 项目正式和试用切换
  virtualmanTypeV2?: string;
  cgiHeader?: any;
  botBizId?: string;
  chatModel?: string;
}

interface SaveProjectRes extends CommonRes {
  virtualmanKey: string;
}

interface GetBotListReq {
  offset: number;
  limit: number;
  filter?: {
    botName: string;
  };
}

interface GetBotListRes extends CommonRes {
  offset: string;
  limit: string;
  records: BotItem[];
}

type GetAuthVirtualmanTypeReq = {
  virtualmanTypeCode?: string;
  virtualmanTypeV2?: string;
  platformCode?: 'virtualman' | 'broadcast_apaas';
};
interface GetAuthVirtualmanTypeRes extends CommonRes {
  virtualmanTypeInfos: virtualmanType[];
}

type GetModelBotListReq = null;

interface GetModelBotListRes extends CommonRes {
  total: string;
  list: ModelBotItem[];
}

type DescribeAuthorizeReq = null;
interface DescribeAuthorizeRes extends CommonRes {
  authorize: number; //  0未授权，1已授权
}

interface SaveAuthorizeReq {
  authorize: number; //  0未授权，1已授权
}

type SaveAuthorizeRes = CommonRes;

// 用户项目列表查询
export const getProjectList = async (data: ProjectListReq) => {
  data.cgiHeader = { 'Authorize-Service-Type': 'ivh' };
  const res = await capi<ProjectListReq, ProjectListRes>('DescribeVirtualmanInstanceImages', data);
  return res;
};

// 项目删除
export const deleteProject = async (data: DeleteProjectReq, noAutoHandle?: boolean) => {
  const res = await capi<DeleteProjectReq, CommonRes>('DeleteVirtualmanInstance', data, !!noAutoHandle);
  return res;
};

// 项目创建
export const saveProject = async (data: SaveProjectReq, noAutoHandle?: boolean) => {
  if (data.repaySource === 'cloudai_gpt') {
    data.cgiHeader = { 'Authorize-Service-Type': 'ivh' };
  }
  const res = await capi<SaveProjectReq, SaveProjectRes>('SaveInstanceConfig', data, !!noAutoHandle);
  return res;
};

// 查询机器人列表
export const getBotList = async (data: GetBotListReq) => {
  const res = await capi<GetBotListReq, GetBotListRes>('DescribeBots', data);
  return res;
};

// 查询授权数智人类型
export const getAuthVirtualmanType = async (data: GetAuthVirtualmanTypeReq) => {
  const res = await capi<GetAuthVirtualmanTypeReq, GetAuthVirtualmanTypeRes>('DescribeVirtualmanTypeInfo', data);
  return res;
};

// 查询机器人列表
export const getModelBotList = async () => {
  const res = await capi<GetModelBotListReq, GetModelBotListRes>('ListRobot', null, true, 'model');
  return res;
};

// 查询授权
export const queryAuthorize = async () => {
  const res = await capi<DescribeAuthorizeReq, DescribeAuthorizeRes>('DescribeAuthorize', null);
  return res;
};

// 保存授权
export const saveAuthorize = async (data: SaveAuthorizeReq) => {
  const res = await capi<SaveAuthorizeReq, SaveAuthorizeRes>('SaveAuthorize', data);
  return res;
};
