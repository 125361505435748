import capi from '../capi/instance';

import { CommonRes } from '../../types/index';

import { VideoResourceItem, VideoTempType } from 'redux/bvh/types';

// 获取平台配置 req
export type GetPlatformConfReq = {
  makeType: number;
};

// 获取平台配置 res
export type GetPlatformConfRes = CommonRes & {
  configs: {
    configName: string;
    configContents: {
      fileId: string;
      makeType: number;
      name: string;
      sequence: number;
      type: number;
      url: string;
      value: number;
    }[];
  }[];
};

// 获取平台配置
export const getPlatformConf = async (data: GetPlatformConfReq) => {
  const res = await capi<GetPlatformConfReq, GetPlatformConfRes>('DescribePlatformConf', data);
  return res;
};

// 试听接口 req
export type GetBroadcastTTSReq = {
  timbreKey: string;
  inputSsmlArray: string[];
  speed: number;
  resourceId?: number;
  isAudition?: boolean;
  emotionCategory?: string;
  timbreLanguage?: string;
  emotionIntensity?: number;
  timbreCategory?: string;
  ticket?: string;
  randomData?: string;
  volume?: number;
};

// 试听接口 res
export type GetBroadcastTTSRes = CommonRes & {
  taskIds: string[];
};
// 试听接口
export const getBroadcastTTS = async (data: GetBroadcastTTSReq, noAutoHandle?: boolean) => {
  const res = await capi<GetBroadcastTTSReq, GetBroadcastTTSRes>('BroadcastTTS', data, !!noAutoHandle);
  return res.taskIds;
};

// new 试听接口
export const getNewBroadcastTTS = async (data: GetBroadcastTTSReq) => {
  const res = await capi<GetBroadcastTTSReq, GetBroadcastTTSRes>('BroadcastAudioTTS', data);
  return res.taskIds;
};
// 二次编辑接获取详情 req
export type GetBroadcastVideoDetailReq = {
  name: string;
};
// 二次编辑接获取详情 res
export type GetBroadcastVideoDetailRes = CommonRes & {
  record: {
    name: string;
    title: string;
    createTime: number;
    updateTime: number;
    done: number;
    videoUrl: string;
    subtitlesUrl: string;
    defaultUrl: string;
    duration: string;
    data: string;
    makeType: number;
    taskErrorCode: string;
    taskErrorMessage: string;
    driverType: number;
    virtualmanKey: string;
    // isDraft?: boolean;
    speechInfo: {
      timbreKey: string;
      platformSource: number;
      emotionIntensity: number;
      emotionCategory: string;
      timbreLanguage?: string;
      volume: number;
    };
    smallSampleParam: {
      videoTemplateType: VideoTempType;
      videoStartIndex?: number;
      videoEndIndex?: number;
    };
  };
};

// 二次编辑接获取详情
export const getBroadcastVideoDetail = async (data: GetBroadcastVideoDetailReq, noAutoHandle?: boolean) => {
  const res = await capi<GetBroadcastVideoDetailReq, GetBroadcastVideoDetailRes>(
    'DescribeBroadcastVideoDetail',
    data,
    !!noAutoHandle,
  );
  return res;
};

// 音视频制作进度轮训 req
export type GetBroadcastVideoProgressReq = {
  name: string;
};
// 音视频制作进度轮训 res
export type GetBroadcastVideoProgressRes = CommonRes & {
  mission: {
    videoUrl: string;
    defaultUrl: string;
    subtitlesUrl: string;
    totalTime: string;
    progress: number;
    needTime: number;
    status: number;
    remainingTime: number;
    taskId: string;
  };
};
// 音视频制作进度轮训
export const getBroadcastVideoProgress = async (data: GetBroadcastVideoProgressReq) => {
  const res = await capi<GetBroadcastVideoProgressReq, GetBroadcastVideoProgressRes>(
    'DescribeBroadcastVideoProgress',
    data,
    true,
  );
  return res.mission;
};

// 获取用户资源 req
export type GetUserResourceReq = {
  makeType?: number;
};

// 获取用户资源 res
export type GetUserResourceRes = CommonRes & {
  userResource: {
    background: VideoResourceItem[];
    logo: VideoResourceItem[];
    head: VideoResourceItem[];
    tail: VideoResourceItem[];
  };
};

// 获取用户资源
export const getUserResource = async (data: GetUserResourceReq) => {
  const res = await capi<GetUserResourceReq, GetUserResourceRes>('DescribeUserResource', data);
  return res;
};

// 上传资源 req
export type UploadUserResourceReq = {
  type: number;
  makeType: number;
  fileUrl: string;
  videoResolution?: string;
};

// 获取用户资源 res
export type UploadUserResourceRes = CommonRes & {
  fileId: string;
  fileUrl: string;
  name: string;
  imageId: string;
  imageUrl: string;
  type: number;
};

// 获取用户资源
export const uploadUserResource = async (data: UploadUserResourceReq) => {
  const res = await capi<UploadUserResourceReq, UploadUserResourceRes>('UploadResource', data);
  return res;
};

// 获取输出设置配置信息 req
export type GetOutputConfReq = {
  dictKeyList: string[];
};

// 获取平台配置 res
export type GetOutputConfRes = CommonRes & {
  configs: {
    confKey: string;
    confValue: string;
  }[];
};

// 获取平台配置
export const getOutputConf = async (data: GetOutputConfReq) => {
  const res = await capi<GetOutputConfReq, GetOutputConfRes>('DescribeDictConfig', data);
  return res;
};

// 删除用户资源 req
export type DelUserResourceReq = {
  type: number;
  id: string;
};

// 删除用户资源 res
export type DelUserResourceRes = CommonRes & {};

// 删除用户资源
export const delUserResource = async (data: DelUserResourceReq) => {
  const res = await capi<DelUserResourceReq, DelUserResourceRes>('DeleteResource', data);
  return res;
};
type VideoParam = {
  backgroundFileUrl: string;
  videoHeadFileUrl: string;
  videoTailFileUrl: string;
  showSubtitles: boolean;
  logoParams: logoParam[];
  anchorParam: anchorParam;
  smallSampleParam?: {
    videoTemplateType: VideoTempType;
    videoStartIndex?: number;
    videoEndIndex?: number;
  };
};
type SpeechParam = {
  timbreKey: string;
  speed?: number;
  volume?: number;
  emotionCategory?: string;
  timbreLanguage?: string;
  emotionIntensity?: number;
};
// 音视频播报保存公用参数 req
export type SaveBvhCommonReq = {
  resourceId: number;
  inputSsml: string;
  videoParam: VideoParam;
  speechParam: SpeechParam;
  data: string;
  driverType: number;
  imageTemplateId?: number;
  draftName?: string;
};
// 生成视频 req
export type SaveVideoReq = SaveBvhCommonReq & {
  videoName: string;
  videoFormat: number;
  makeType: number;
  thirdplatformTaskType?: string;
};
// 保存草稿 req
export type SaveBroadcastDraftReq = SaveBvhCommonReq & {
  anchorName?: string;
};
// 保存草稿 res
export type SaveBroadcastDraftRes = CommonRes & {
  message: string;
  draftName: string;
};

export type logoParam = {
  logoFileUrl: string;
  positionX: number;
  positionY: number;
  scale: number;
};
export type anchorParam = {
  scale: number;
  horizontalPosition: number;
  verticalPosition: number;
  isFixedScale: boolean; // 缩放系数控制方式: true-完全由参数控制,false-后台添加缩放系数
  angle?: number;
  color?: string;
  clothesMeta?: string;
};
// 生成视频 res
export type SaveVideoRes = CommonRes & {
  name: string;
  needTime: number;
  anchorSolution: string;
  draftStatus?: {
    code: string;
    message: string;
  };
};

// 生成视频
export const saveVideo = async (data: SaveVideoReq) => {
  const res = await capi<SaveVideoReq, SaveVideoRes>('BroadcastVideo', data);
  return res;
};

// 生成音频 req
export type SaveAudioReq = {
  timbreKey: string;
  inputSsml: string;
  speed: string | number;
  resourceId: number;
  data: string;
  audioName: string;
  makeType: number;
  volume?: number;
  emotionCategory?: string;
  timbreLanguage?: string;
  emotionIntensity?: number;
  draftName?: string;
};

// 生成音频 res
export type SaveAudioRes = CommonRes & {
  name: string;
  needTime: number;
  anchorSolution: string;
};

// 生成音频
export const saveAudio = async (data: SaveAudioReq) => {
  const res = await capi<SaveAudioReq, SaveAudioRes>('BroadcastAudio', data);
  return res;
};

// 检测名称重复 req
export type CheckNameReq = {
  title: string;
  makeType?: number;
};

// 检测名称重复 res
export type CheckNameRes = CommonRes & {
  name: string;
  needTime: number;
  anchorSolution: string;
};

// 检测名称重复
export const checkName = async (data: CheckNameReq, noAutoHandle?: boolean) => {
  const res = await capi<CheckNameReq, CheckNameRes>('CheckNameRepeat', data, !!noAutoHandle);
  return res;
};

// 判断是否中英混合 req
export type ValidateInputReq = {
  inputSsml: string;
  timbreCode: string;
};

// 判断是否中英混合 res
export type ValidateInputRes = CommonRes & {
  code: number; // 0-校验通过，1-需要二次确认，2-校验未通过
  message: string; // 校验的信息
};
// 获取制作中音视频 req
export type GetMakingReq = {
  makeType: number;
  virtualmanTypeV2?: string;
};

// 获取制作中音视频 res
export type GetMakingRes = CommonRes & {
  makingVideo: {
    name?: string;
    wordCoun?: number;
    progress?: string;
    remainingTime?: number;
    makeType?: number;
  };
};

// 获取制作中音视频
export const getMakingVideo = async (data: GetMakingReq) => {
  const res = await capi<GetMakingReq, GetMakingRes>('DescribeBroadcastMakingVideo', data);
  return res;
};
// 判断是否中英混合
export const validateInput = async (data: ValidateInputReq) => {
  const res = await capi<ValidateInputReq, ValidateInputRes>('ValidateInput', data);
  return res;
};

export type GetUserVideosReq = {
  page: number;
  pageSize: number;
  makeType: number;
};

export type GetUserVideosRes = CommonRes & {
  records: {
    name: string;
    title: string;
    createTime: number;
    updateTime: number;
    done: number;
    videoUrl: string;
    subtitlesUrl: string;
    defaultUrl: string;
    duration: string;
    data: string;
    makeType: number;
    taskErrorCode: string;
    taskErrorMessage: string;
    isDelete?: boolean;
    virtualmanTypeV2: string;
  }[];
  count: number;
  page: number;
  pageSize: number;
};
// 获取用户视频列表
export const getUserVideos = async (data: GetUserVideosReq) => {
  const res = await capi<GetUserVideosReq, GetUserVideosRes>('DescribeUserBroadcastVideos', data);
  return res;
};

// 2D卡通制作进度轮训
export type Get2DCartoonProgressResReq = {
  name: string;
};
// 2D卡通制作进度轮训
export type Get2DCartoonProgressRes = CommonRes & {
  mission: {
    videoUrl: string;
    defaultUrl: string;
    subtitlesUrl: string;
    totalTime: string;
    progress: number;
    needTime: number;
    status: number;
    remainingTime: number;
    taskId: string;
  };
};
// 2D卡通制作进度轮训
export const get2DCartoonProgress = async (data: Get2DCartoonProgressResReq) => {
  const res = await capi<Get2DCartoonProgressResReq, Get2DCartoonProgressRes>('DescribeCartoonVideoProgress', data);
  return res.mission;
};

// 纯手语视频制作进度轮训
export type GetOnlySignVideoProgressReq = {
  name: string;
};
// 纯手语视频制作进度轮训
export type GetOnlySignVideoProgressRes = CommonRes & {
  mission: {
    videoUrl: string;
    defaultUrl: string;
    subtitlesUrl: string;
    totalTime: string;
    progress: number;
    needTime: number;
    status: number;
    remainingTime: number;
    taskId: string;
  };
};
// 纯手语视频制作进度轮训
export const getOnlySignVideoProgress = async (data: GetOnlySignVideoProgressReq) => {
  const res = await capi<GetOnlySignVideoProgressReq, GetOnlySignVideoProgressRes>(
    'DescribeSimpleSignLanguageVideoProgress',
    data,
  );
  return res.mission;
};

// 嵌入式手语视频制作进度轮训
export type GetEmbedSignLanguageProgressReq = {
  name: string;
};
// 嵌入式手语视频制作进度轮训
export type GetEmbedSignLanguageProgressRes = CommonRes & {
  mission: {
    videoUrl: string;
    defaultUrl: string;
    subtitlesUrl: string;
    totalTime: string;
    progress: number;
    needTime: number;
    status: number;
    remainingTime: number;
    taskId: string;
  };
};
// 纯手语视频制作进度轮训
export const getEmbedSignLanguageProgress = async (data: GetEmbedSignLanguageProgressReq) => {
  const res = await capi<GetEmbedSignLanguageProgressReq, GetEmbedSignLanguageProgressRes>(
    'DescribeEmbedSignLanguageVideoProgress',
    data,
  );
  return res.mission;
};

// 删除用户视频
export type DelUserVideoReq = {
  name: string;
};

// 删除用户视频
export type DelUserVideoRes = CommonRes & {};

// 删除用户视频
export const deleteUserVideo = async (data: DelUserVideoReq) => {
  const res = await capi<DelUserVideoReq, DelUserVideoRes>('DeleteUserVideo', data);
  return res;
};

/** 重命名音视频 */
export type RenameBvhTitleReq = {
  name: string;
  title: string;
};

export type RenameBvhTitleRes = CommonRes & {};

export const renameBvhVideo = async (data: RenameBvhTitleReq) => {
  const res = await capi<RenameBvhTitleReq, RenameBvhTitleRes>('RenameBroadcastTitle', data);
  return res;
};

/** 播报视频预览 */
export type BroadcastVideoPreviewReq = {
  resourceId: number;
  inputAudioUrl: string;
  videoTemplateType: VideoTempType;
  videoStartIndex?: number;
  videoEndIndex?: number;
};

export type BroadcastVideoPreviewRes = CommonRes & {
  name: string;
};

export const postBroadcastVideoPreview = async (data: BroadcastVideoPreviewReq) => {
  const res = await capi<BroadcastVideoPreviewReq, BroadcastVideoPreviewRes>('BroadcastVideoPreview', data);
  return res;
};

/** 取消视频预览任务 */
export type StopVideoPreviewTaskReq = {
  name: string;
};
export type StopVideoPreviewTaskRes = CommonRes;
export const stopVideoPreviewTask = async (data: StopVideoPreviewTaskReq) => {
  const res = await capi<StopVideoPreviewTaskReq, StopVideoPreviewTaskRes>('StopVideoPreviewTask', data);
  return res;
};

// 保存草稿
export const saveBroadcastDraft = async (data: SaveBroadcastDraftReq) => {
  const res = await capi<SaveBroadcastDraftReq, SaveBroadcastDraftRes>('SaveBroadcastDraft', data);
  return res;
};
