import i18n from 'utils/i18n';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ResponseBody } from './types';
import API_ERROR from './errors';
import utils from '../../utils'; // 服务端接口cgi目前为url形式
import store from '../../redux/store';
import { CamelCasedPropertiesDeep, PascalCasedPropertiesDeep } from 'type-fest';
import { MessagePlugin } from 'tdesign-react';
import { UPDATE_CAM_MSG } from 'redux/action_types';
const { t } = i18n;
/**
 * 云api调用相关公共参数
 */
// 调用地区
const regionId = 1;
// 所属产品
const serviceType = 'ivh';

/**
 * 生成UUID
 * @returns {string} UUID，形式如 xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx，其中x的范围为0~9a-f
 */
export const getUUID = () => {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
};
/**
 * axios配置
 */
const timeout = 20000;
const apiPath = '/cgi/capi'; // 云小微测试api
const axiosInstance = axios.create({
  timeout,
});

const rejectError = (code: string, message: string) => {
  /**
   * todo: 错误上报
   */
  return Promise.reject({ code, message });
};

const onReqFulfilled = (config: AxiosRequestConfig) => {
  /**
   * todo
   */
  return config;
};
const onReqRejected = (error: any) => {
  return rejectError(API_ERROR.AXIOS_REQ, error?.message || 'axios request error');
};
const onResFulfilled = (res: AxiosResponse<ResponseBody<object>>) => {
  try {
    /**
     * axios没有返回数据
     */
    if (!res?.data) {
      return rejectError(API_ERROR.AXIOS_RES_NULL, 'axios response null');
    }
    const { code, data, message } = res.data;
    /**
     * api代理返回非19001，说明鉴权错误（如：登录态失效、无登录态）
     */
    // if (code === '0104') {
    if (code === 19001) {
      // 微前端传参
      const {
        commonInfo: { toLogin },
      } = store.getState();
      toLogin();
      return rejectError(API_ERROR.API_PROXY, message || t('请重新登录'));
    }
    /**
     * api代理返回非0，说明代理层调用接口出错
     */
    if (code !== 0) {
      return rejectError(API_ERROR.API_PROXY, message || 'api proxy error');
    }
    /**
     * 业务没有返回响应数据
     */
    if (!data?.Response) {
      return rejectError(API_ERROR.MISS_RESPONSE, 'miss response');
    }
    const { /* RequestId, */ Error } = data.Response;
    /**
     * 业务返回错误
     */
    if (Error?.Code) {
      if (Error.Code === 'AuthFailure') {
        return rejectError(Error.Code, t('抱歉，您没有该页面的权限。'));
      }
      return rejectError(Error.Code, Error.Message);
    }
    return res.data;
  } catch (error) {
    return rejectError(API_ERROR.AXIOS_RES, (error as Error)?.message || 'unknown axios response error');
  }
};
const onResRejected = (error: any) => {
  return rejectError(API_ERROR.AXIOS_RES, error?.message || 'axios response error');
};
axiosInstance.interceptors.request.use(onReqFulfilled, onReqRejected);
axiosInstance.interceptors.response.use(onResFulfilled, onResRejected);

/**
 * 通用请求函数
 */
declare type GenericRequestFn = <T, U>(action: string, data: T, key?: string) => Promise<ResponseBody<U>>;
/**
 * eg:
 * interface UserInfoReq {...}
 * interface UserInfoRes {...}
 * const req: UserInfoReq = {...};
 * const res = await capi.request<UserInfoReq, UserInfoRes>('GetUserInfo', req);
 * 返回的 res 类型为ResponseBody<UserInfoRes>
 * 具体可参考types对ResponseBody的类型定义
 * res.data.Response字段类型为UserInfoRes
 * @param action 请求接口名
 * @param data 请求数据
 * @returns 返回数据，业务定义的返回数据相同类型
 */
export const request: GenericRequestFn = (action, data, key) => {
  console.log('data datadatadatadata', data);
  const tempCgiHeader = (data as any)?.CgiHeader || {};
  if ((data as any)?.CgiHeader) {
    delete (data as any)?.CgiHeader;
    delete (data as any)?.from;
  }
  // 获取用户信息接口特殊处理
  if (action === 'DescribeLoginUserInfo') {
    const requestUserData: {
      regionId: number;
      serviceType: string;
      cmd: string;
      data: {};
      token?: string;
      from?: string;
      fromServiceType?: string;
    } = { regionId, serviceType: 'ihava', cmd: action, data: {} };
    if (
      utils.getIframeQueryVariable('iframe') === 'true' &&
      utils.getIframeQueryVariable('token') &&
      utils.getIframeQueryVariable('from')
    ) {
      requestUserData.token = utils.getIframeQueryVariable('token');
      requestUserData.from = utils.getIframeQueryVariable('from');
      requestUserData.fromServiceType = 'ivh';
    }
    return axiosInstance.post(`${apiPath}?cmd=${action}`, requestUserData);
  }

  // 微前端传参
  const {
    commonInfo: { platformType, reqHeader, reqExt },
    projectInfo: { virtualmanKey },
    userInfo: { uin, skey },
  } = store.getState();
  // 数智人内部系统，virtualmanKey为公用参数
  if (platformType === 'virtualman') {
    reqHeader.uin = uin;
    reqHeader.skey = skey;
    // reqHeader.Authorize_Service_Type = 'ivh';
    reqHeader.virtualmanKey = ['SaveInstanceConfig', 'QueryPermissions'].includes(action) ? '' : virtualmanKey;
  }
  let Header = utils.jsonFormat(reqHeader, 'pascal');
  const newData: any = data;
  if (newData && newData.Header) {
    const headerData = utils.jsonFormat(newData.Header, 'pascal');
    Header = { ...Header, ...headerData };
    delete newData.Header;
  }
  // 国际化传参
  if (i18n.language) {
    Header = { ...Header, Language: i18n.language === 'en' ? 'en-US' : 'zh-CN' };
  }

  const modelData: any = {};
  if (key === 'model') {
    modelData.from = '';
    modelData.serviceType = 'lke';
    modelData.token = '';
    modelData.data = { ...newData };
  }
  const requestData: {
    regionId: number;
    serviceType: string;
    cmd: string;
    data: any;
    ext: any;
    token?: string;
    from?: string;
    fromServiceType?: string;
  } = {
    regionId,
    serviceType,
    cmd: action,
    data: {
      Header,
      ...(newData && { Payload: newData }),
    },
    ext: Object.keys(reqExt).length > 0 ? utils.jsonFormat(reqExt, 'pascal') : undefined,
    ...modelData,
  };
  console.log(
    'getIframeQueryVariable',
    utils.getIframeQueryVariable('iframe') === 'true',
    utils.getIframeQueryVariable('token'),
    utils.getIframeQueryVariable('from'),
  );
  if (
    utils.getIframeQueryVariable('iframe') === 'true' &&
    utils.getIframeQueryVariable('token') &&
    utils.getIframeQueryVariable('from')
  ) {
    requestData.token = utils.getIframeQueryVariable('token');
    requestData.from = utils.getIframeQueryVariable('from');
    requestData.fromServiceType = 'ivh';
    // if (tempCgiHeader?.['Authorize-Service-Type']) {
    //   delete tempCgiHeader['Authorize-Service-Type'];
    // }
  }
  return axiosInstance.post(`${apiPath}?cmd=${requestData.cmd}`, requestData, {
    headers: {
      'X-Qcloud-Transaction-Id': getUUID(),
      ...tempCgiHeader,
    },
  });
};

// 解构 TODO 加上类型就报错，不知道该怎么写
const deconstruct = <T, U>(action: string, data: T, noAutoHandle = false, key?: string): Promise<U> => {
  return new Promise((resolve, reject) => {
    request<PascalCasedPropertiesDeep<T>, U>(action, utils.jsonFormat(data, 'pascal'), key)
      .then((res) => res.data.Response)
      .then((res) => resolve(utils.jsonFormat(res, 'camel')))
      .catch((err) => {
        if (err.code === 'AuthFailure.UnauthorizedOperation') {
          store.dispatch({ type: UPDATE_CAM_MSG, camMsg: err.message });
        } else {
          // 判断autoHandle，无特殊处理弹窗提示错误
          !noAutoHandle && MessagePlugin.error(utils.formatErrorMessage(err.message));
        }
        reject(err);
      });
  });
};

export default deconstruct;
