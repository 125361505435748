// api接入
import capi from '../capi/instance';

import { CommonRes } from '../../types/index';

// 数智人密钥信息查询 req
// export type GetSecretDetailReq = {} | null;

// 数智人密钥信息查询 res
export type GetSecretDetailRes = CommonRes & {
  accessKey: string;
  secretKey: string;
  docPath: string;
  requestUrl: string;
};

type GetSecretDetailReq =
  | {
      Header: {
        PlatformCode?: string;
        Uin?: string;
        VirtualmanKey: string;
      };
      hide?: number;
    }
  | { hide?: number };

// 数智人新协议密钥信息查询 res
export type GetNewSecretDetailRes = CommonRes & {
  customerSecrets: {
    accessKey: string;
    secretKey: string;
    docPath: string;
    sign: string;
    projectKey: string;
    requestUrl: string;
  }[];
};

// 数智人密钥信息查询
export const getSecretDetail = async (data?: GetSecretDetailReq) => {
  const res = await capi<GetSecretDetailReq, GetSecretDetailRes>('DescribeVirtualmanSecretDetail', data || {});
  return res;
};
// 数智人新协议密钥信息查询
export const getNewSecretDetail = async (data?: GetSecretDetailReq) => {
  const res = await capi<GetSecretDetailReq, GetNewSecretDetailRes>('DescribeSecret', data || {});
  return res;
};
