export const API_DATA = 'data';
export const API_CODE = 'code';
export const API_MESSAGE = 'message';
// 接口请求成功
export const SUCCESS_CODE = '000000';
// 未登录错误码
export const NEED_LOGIN_CODE = ['030001'];

export const apassUrlMap = {
  // local: 'https://gw.dev.tvs.qq.com',
  local: 'https://gw.test.tvs.qq.com',
  test: 'https://gw.test.tvs.qq.com',
  development: 'https://gw.dev.tvs.qq.com',
  gray: 'https://gwgray.tvs.qq.com',
  production: 'https://gw.tvs.qq.com',
};
