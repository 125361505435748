import i18n from 'utils/i18n';
import axios from 'axios';
import qs from 'qs';
import privateUtils from 'utils/private';
import { getCloudLoginUrl } from 'utils/auth';
import { API_DATA, API_CODE, API_MESSAGE, SUCCESS_CODE, NEED_LOGIN_CODE } from './const';
import { MessagePlugin } from 'tdesign-react';
const { t } = i18n;
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // 1. http 状态码非2开头（没有额外定义 validateStatus）的都会进来这里，如 404, 500 等，error 的数据结构如下：error-400、error-500
    // 2. 取消请求也会进入这里，可以用 axios.isCancel(error) 来判断是否是取消请求，error 的数据结构如下：cancel-error
    // 3. 请求运行有异常也会进入这里，如故意将 headers 写错：axios.defaults.headers = '123'
    // 4. 断网，error 的数据结构如下：network-error
    if (/5\d{2}/.test(error.message)) {
      MessagePlugin.error(t('系统繁忙，正在维护中，请稍候再尝试操作'));
    }
  },
);
const request = (opts: any) => {
  const url = typeof opts === 'string' ? opts : opts.url || '';
  const method = (opts.method || 'get').toLowerCase();
  const dataType = opts.dataType || '';
  let params = opts.params || {};
  if (opts.params instanceof FormData) {
    params.append('t', Date.now());
  } else {
    params = {
      t: Date.now(),
      ...(opts.params || {}),
    };
  }
  if (url.length === 0) {
    console.warn(t('utils.request：url未指定'));
    return;
  }
  return new Promise((resolve, reject) => {
    if (method === 'get' || method === 'delete') {
      params = { params };
    } else if (dataType === 'formData') {
      if (!(params instanceof FormData)) {
        const formData = new FormData();
        Object.keys(params).forEach((key) => {
          formData.append(key, params[key]);
        });
        params = formData;
      }
    } else if (dataType !== 'json') {
      params = qs.stringify(params);
    }
    // @ts-ignore
    axios[method](url, params)
      .then((res: any) => {
        if (res.status === 200) {
          const { [API_CODE]: code, [API_DATA]: data, [API_MESSAGE]: message } = res.data;
          if (code === SUCCESS_CODE) {
            resolve({ data, code, message });
          } else if (NEED_LOGIN_CODE.includes(code)) {
            const loginUrl = getCloudLoginUrl({ project: 'virtualman' });
            window.location.href = loginUrl;
            reject({ data, code, message });
          } else if (res.data.Payload && res.data.Header) {
            const {
              Payload,
              Header: { Code, Message },
            } = res.data;
            resolve({ data: Payload, code: Code, message: Message });
          } else {
            reject({ data, code, message });
          }
        } else {
          reject({ code: res.status, msg: res.errMsg || t('系统错误，请稍后重试！') });
        }
      })
      .catch((res: any) => {
        reject({ code: 500, data: res, msg: 'network error' || t('网络错误，请稍后重试！') });
      });
  });
};
export default request;
