/** 封装播报格式请求 */
import request from './request';
import apiMap from './api_map';
import { MessagePlugin } from 'tdesign-react';
const errorHandle = (res: any) => {
  MessagePlugin.error(res.message || res.msg);
};
const apiFetch = (opts: any) => {
  const { apiName } = opts;
  if (!apiName) {
    console.error(`apiName is not defined!`);
    return;
  }
  // 检测并获取URL
  if (apiMap[apiName]) {
    const autoHandle = opts.autoHandle !== false;
    return new Promise((resolve, reject) => {
      // @ts-ignore
      request({ ...opts, url: apiMap[apiName] })
        .then((res) => {
          resolve(res);
        })
        .catch((res) => {
          autoHandle && errorHandle(res);
          reject(res);
        });
    });
  }
  console.error(`api[${apiName}] is not in apiMap!`);
  return new Promise(() => {});
};

const apiGet = (apiName: string, params?: any, opts?: any) => apiFetch({ apiName, method: 'get', params, ...opts });

const apiGetJson = (apiName: string, params?: any, opts?: any) =>
  apiFetch({ apiName, method: 'get', dataType: 'json', params, ...opts });

const apiPost = (apiName: string, params?: any, opts?: any) => apiFetch({ apiName, method: 'post', params, ...opts });

const apiPostJson = (apiName: string, params?: any, opts?: any) =>
  apiFetch({ apiName, method: 'post', dataType: 'json', params, ...opts });

export { apiGet, apiGetJson, apiPost, apiPostJson };
