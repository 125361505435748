import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';

import apassRequest from '../api/apass-request';
import store from '../../redux/store';

import { getNewSecretDetail, getSecretDetail } from './api_detail';
import { apassUrlMap } from '../api/const';

export type OriginKey = 'production' | 'gray' | 'test' | 'development';

const {
  commonInfo: { reqHeader },
} = store.getState();

export interface ApaasData {
  Protocol?: 'rtmp' | 'trtc' | 'webrtc';
  DriverType: number;
  UserId: string;
  ExtraInfo?: { UserInfo?: string; ImageTempleteId?: number };
  Type?: 'formal' | 'trial' | 'preview';
  ProtocolOption?: { TrtcRoomId?: number };
}

export const generateUserID = () => {
  return `pc_${Date.now()}_${Math.random().toFixed(5)}`;
};

export const getPreviewUrl = async (path: string, opts: any, data?: any) => {
  let appkey = '';
  let accesstoken = '';
  let requestUrl = '';
  if (opts?.Type === 'preview' && opts?.Appkey && opts?.AccessToken) {
    appkey = opts?.Appkey;
    accesstoken = opts?.AccessToken;
  }
  if (opts.archVersion === 2) {
    const { customerSecrets } = await getNewSecretDetail();
    if (customerSecrets.length) {
      const { accessKey, secretKey, requestUrl: url } = customerSecrets?.[0];
      appkey = accessKey;
      accesstoken = secretKey;
      requestUrl = url;
    }
  } else {
    const {
      accessKey,
      secretKey,
      requestUrl: url,
    } = await getSecretDetail({
      Header: { ...reqHeader, ...(opts.Header || {}) },
    });
    appkey = accessKey;
    accesstoken = secretKey;
    requestUrl = url;
  }
  const env = process.env.REACT_APP_ENVIRONMENT;
  // @ts-ignore
  const host = ['production', 'intl'].includes(env) ? requestUrl || apassUrlMap[env] : apassUrlMap[env];
  const timestamp = parseInt((new Date().getTime() / 1000).toString(), 10);
  const signatureData = { appkey, timestamp, ...data };
  const sortKeys = Object.keys(signatureData).sort();
  const keyValueString = sortKeys.reduce((prev, cur) => {
    return `${prev}${prev ? '&' : ''}${cur}=${signatureData[cur]}`;
  }, '');
  const signature = encodeURIComponent(hmacSHA256(keyValueString, accesstoken).toString(Base64));
  return `${host}${path}?${keyValueString}&signature=${signature}`;
};

/**
 * 创建流
 */
export const apaasNewStream = async (data: any, opts: any, archVersion?: number) => {
  const urlPath =
    archVersion === 2
      ? '/v2/ivh/sessionmanager/sessionmanagerservice/createsession'
      : '/v2/ivh/streammanager/streamservice/newsession';
  const storageUserId = localStorage.getItem('UserId');
  const newData = { ...data };
  newData.UserId = data.UserId || storageUserId || generateUserID();
  if (newData.UserId !== storageUserId) {
    localStorage.setItem('UserId', newData.UserId);
  }
  const url = await getPreviewUrl(urlPath, { ...opts, archVersion });
  return apassRequest(url, newData, true, archVersion);
};
/**
 *
 * 开启对话
 */
export const apaasStartStream = async (data: any, opts: any, archVersion?: number) => {
  const urlPath =
    archVersion === 2
      ? '/v2/ivh/sessionmanager/sessionmanagerservice/startsession'
      : '/v2/ivh/streammanager/streamservice/startsession';
  const url = await getPreviewUrl(urlPath, { ...opts, archVersion });
  return apassRequest(url, data, true, archVersion);
};
/**
 * 关闭流
 */
export const apaasCloseStream = async (data: any, opts: any, noMessage?: boolean) => {
  // eslint-disable-next-line prefer-destructuring
  const archVersion = data.archVersion;
  const urlPath =
    archVersion === 2
      ? '/v2/ivh/sessionmanager/sessionmanagerservice/closesession'
      : '/v2/ivh/streammanager/streamservice/closesession';
  const url = await getPreviewUrl(urlPath, { ...opts, archVersion });
  // eslint-disable-next-line no-param-reassign
  delete data.archVersion;
  return apassRequest(url, data, !noMessage, archVersion);
};

/**
 * 查询会话状态
 */
export const apaasStateSession = async (data: any, opts: any, archVersion?: number) => {
  const urlPath =
    archVersion === 2
      ? '/v2/ivh/sessionmanager/sessionmanagerservice/statsession'
      : '/v2/ivh/streammanager/streamservice/statsession';
  const url = await getPreviewUrl(urlPath, { ...opts, archVersion });
  return apassRequest(url, data, true, archVersion);
};

export const apaasConnectSocket = async (data: any, opts: any, archVersion?: number) => {
  const urlPath =
    archVersion === 2
      ? '/v2/ws/ivh/interactdriver/interactdriverservice/commandchannel'
      : '/v2/ws/ivh/streammanager/streamservice/commandchannel';
  const url = await getPreviewUrl(urlPath, { ...opts, archVersion }, data);
  return url.replace('http', 'ws');
};

export const apaasGetTrtcSign = async (data: any, opts: any, archVersion?: number) => {
  const urlPath =
    archVersion === 2
      ? '/v2/ivh/sessionmanager/sessionmanagerservice/gettrtcsign'
      : '/v2/ivh/streammanager/streamservice/gettrtcsign';
  const url = await getPreviewUrl(urlPath, { ...opts, archVersion }, data);
  return apassRequest(url, data, true, archVersion);
};

/**
 * 查询排队状态
 */
export const apaasCheckQueuedStatus = async (data: any, opts: any) => {
  // const path = ' /v2/ivh/streammanager/streamservice/sessionapplystatus';
  // const url = await getPreviewUrl(path, opts, data);
  // return apassRequest(url, data);
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ State: 'queued', QueuedSessionsCount: 10, CreatedAt: 1232131212, PlayStreamAddr: '' });
    }, 200);
  });
};

/**
 * 修改配置
 */
export const apaasChangeConfig = async (data: any, opts: any) => {
  // const path = '/v2/ivh/streammanager/streamservice/configsession';
  // const url = await getPreviewUrl(path, opts, data);
  // return apassRequest(url, data);
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 200);
  });
};

/**
 * 文本驱动
 */
export const apaasTextCommand = async (data: any, opts: any) => {
  // const path = ' /v2/ivh/streammanager/streamservice/command';
  // const url = await getPreviewUrl(path, opts, data);
  // return apassRequest(url, data);
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 200);
  });
};

/**
 * 动作预览
 */
export const apaasPreviewAction = async (data: any, opts: any) => {
  // const path = ' /v2/ivh/streammanager/streamservice/command';
  // const url = await getPreviewUrl(path, opts, data);
  // return apassRequest(url, data);
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 200);
  });
};

export const getNewPreviewUrl = async (path: string, customerSecrets: any) => {
  let [appkey, accesstoken] = ['', ''];
  const { accessKey, secretKey } = customerSecrets;
  [appkey, accesstoken] = [accessKey, secretKey];
  const env = process.env.REACT_APP_ENVIRONMENT as keyof typeof apassUrlMap;
  // const env = 'test';
  const host = apassUrlMap[env];
  const timestamp = parseInt((new Date().getTime() / 1000).toString(), 10);
  const signatureData = { appkey, timestamp } as any;
  const sortKeys = Object.keys(signatureData).sort();
  const keyValueString = sortKeys.reduce((prev, cur) => {
    return `${prev}${prev ? '&' : ''}${cur}=${signatureData[cur]}`;
  }, '');
  const signature = encodeURIComponent(hmacSHA256(keyValueString, accesstoken).toString(Base64));
  return `${host}${path}?${keyValueString}&signature=${signature}`;
};

/**
 * 提交人脸识别任务
 */
export const apaasFaceDetect = async (data: any, opts: any) => {
  const path = '/v2/ivh/facedetectserver/facedetectservice/facedetect';
  const { customerSecrets } = await getNewSecretDetail();
  if (customerSecrets.length) {
    const url = await getNewPreviewUrl(path, customerSecrets[0]);
    return apassRequest(url, data);
  }
};

/**
 * 查询人脸识别任务状态
 */
export const getfacedetectstate = async (data: any, opts: any) => {
  const path = '/v2/ivh/facedetectserver/facedetectservice/getfacedetectstate';
  const { customerSecrets } = await getNewSecretDetail();
  if (customerSecrets.length) {
    const url = await getNewPreviewUrl(path, customerSecrets[0]);
    return apassRequest(url, data);
  }
};
