import i18n from 'utils/i18n';
import axios from 'axios';
import { message } from 'antd';
const { t } = i18n;
const apassRequest = async (url: string, Payload: Object, showMessage = true, archVersion?: number) => {
  try {
    const res = await axios.post(
      url,
      { Header: {}, Payload },
      {
        headers: {
          Language: i18n.language === 'en' ? 'en-US' : 'zh-CN',
        },
      },
    );
    if (res.status === 200) {
      const {
        Header: { Code, Message },
        Payload,
      } = res.data;

      let errorMsg = Message;
      switch (Code) {
        case 0:
          return Payload;
        case 401:
          errorMsg = t('数智人正在初始化，请稍后再试');
          break;
        case 110006:
          if (url.indexOf(archVersion === 2 ? '/interactdriverservice/command' : '/streamservice/command') > -1) {
            errorMsg = t('形象播报已超时');
          }
          break;
        default:
          break;
      }
      showMessage && message.info(errorMsg);
      throw new Error(errorMsg);
    } else {
      showMessage && message.info(t('系统错误，请稍后重试！'));
      throw new Error(t('系统错误，请稍后重试！'));
    }
  } catch (err) {
    throw new Error(`${t('系统错误！')}${JSON.stringify(err)}`);
  }
};

export default apassRequest;
